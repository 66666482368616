import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Reset from '@utils/reset';
import mediaQueries from '@utils/media-queries';
import { colors } from '@utils/vars';
import background404 from '@assets/images/404-background.jpg';
import backgroundNoise from '@assets/images/noise.png';
import buttonLeftBorder from '@assets/images/btn-left-side.svg';
import buttonRightBorder from '@assets/images/btn-right-side.svg';

const { highlight: highlightColor } = colors;

const {
  small,
  xsmall,
} = mediaQueries;

const Wrapper = styled.main`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #ff7f00, #ff4e00) 1 100%;
  border-top: none;
  border-bottom: none;
  background: url(${backgroundNoise}) repeat #262626;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background-color: #ff7f00;
  }

  &::after {
    top: unset;
    bottom: 0;
    border-bottom: 4px solid ${highlightColor};
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0.25;
  width: 100%;
  height: 100%;
  background-color: #262626;
  background-image: url(${background404});
  background-position: 50% 0;
  background-size: cover;
  pointer-events: none;
`;

const Heading = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.15;
  color: ${highlightColor};
  font-weight: 700;
  font-size: 600px;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;

  @media ${small} {
    font-size: 300px;
  }

  @media ${xsmall} {
    font-size: 150px;
  }
`;

const SubHeading = styled.h2`
  position: relative;
  padding: 0 20px;
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
`;

const LinkPadding = styled.span`
  padding: 0 72px;
  transition: transform 0.4s;
`;

const HomeLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  height: 71px;
  margin: 40px 0 0;
  color: ${highlightColor};
  font-size: 18px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    left: 0;
    min-width: 90px;
    height: 71px;
    background-image: url(${buttonLeftBorder});
    background-size: 378px 71px;
    background-repeat: no-repeat;
    background-position-x: 0;
    pointer-events: none;
  }

  &::after {
    right: 0;
    left: 50%;
    background-image: url(${buttonRightBorder});
    background-position-x: 100%;
  }

  &:active ${LinkPadding} {
    transform: translate(2px, -1px);
  }
`;

const NotFoundPage = () => (
  <>
    <Helmet>
      <link
        rel="stylesheet"
        href="/fonts.css"
      />
    </Helmet>
    <Wrapper>
      <Background />
      <Reset />
      <Heading>404</Heading>
      <SubHeading>There is nothing to see here</SubHeading>
      <HomeLink href="https://www.merixstudio.com/"><LinkPadding>Back to Homepage</LinkPadding></HomeLink>
    </Wrapper>
  </>
);

export default NotFoundPage;
